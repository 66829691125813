const cookieTableStructure = {
    caption: 'Verwendete Cookies',
    headers: {
        name: 'Name',
        description: 'Zweck',
        duration: 'Dauer',
    },
};

const de = {
    consentModal: {
        label: 'Informationen zu Ihrer Privatsphäre auf dieser Website',
        title: 'Ihre Privatsphäre',
        description: `
            <p>Ihre Privatsphäre ist uns wichtig, daher erfassen wir keine personenbezogenen Daten ohne Ihre Zustimmung. Hier können Sie der Nutzung von externen Anbietern für eingebettete Inhalte und zur Erfassung von Besucher-Statistiken zustimmen, dieser widersprechen oder individuelle Einstellungen treffen.</p>
            {{revisionMessage}}`,
        acceptAllBtn: 'Alle akzeptieren',
        acceptNecessaryBtn: 'Nur notwendige',
        showPreferencesBtn: 'Individuelle Einstellungen',
        // closeIconLabel: 'Alle ablehnen und Einstellungen schließen',
        revisionMessage:
            '<p>Wir haben Änderungen an den Privatsphäre-Optionen vorgenommen. Bitte prüfen Sie Ihre Einstellungen.</p>',
        footer: `
            <a href="/datenschutz">Datenschutzerklärung</a>
            <a href="/impressum">Impressum</a>
        `,
    },
    preferencesModal: {
        title: 'Privatsphäre-Einstellungen',
        acceptAllBtn: 'Alle akzeptieren',
        acceptNecessaryBtn: 'Nur notwendige',
        savePreferencesBtn: 'Einstellungen speichern',
        closeIconLabel: 'Einstellungen schließen',
        // serviceCounterLabel: 'Dienst|Dienste',
        sections: [
            {
                description:
                    'Hier können Sie einstellen, welche Cookies und externen Services wir verwenden dürfen, von denen gegebenenfalls personenbezogene Daten erfasst werden. Mehr erfahren Sie in unserer <a href="/datenschutz" class="cc__link">Datenschutzerklärung</a>.',
            },
            {
                title: 'Technisch erforderlich',
                description:
                    'Die hier genannten Cookies sind technisch erforderlich, um Ihre Privatsphäre-Einstellungen zu speichern und gefälschte Formular-Einreichungen zu verhindern.',
                linkedCategory: 'necessary',
                cookieTable: {
                    ...cookieTableStructure,
                    body: [
                        {
                            name: 'privacy_preferences',
                            description: 'Speichert Ihre Privatsphäre-Einstellungen',
                            duration: '365 Tage',
                        },
                        {
                            name: 'CRAFT_CSRF_TOKEN',
                            description: 'Verhindert gefälschte Formulareingaben',
                            duration: 'Session',
                        },
                    ],
                },
            },
            // {
            //     title: 'Statistik',
            //     description:
            //         'Wir nutzen Matomo zur Erfassung von Besucherstatistiken. Alle Daten werden dabei pseudonymisiert und werden nicht an Dritte weitergegeben.',
            //     linkedCategory: 'analytics',
            // },
            {
                title: 'Externe Medien',
                description:
                    'Mit dieser Kategorie können Sie eingebettete Medien von externen Anbietern aktivieren. Dabei können personenbezogene Daten an die genannten Anbieter übertragen werden.',
                linkedCategory: 'media',
                cookieTable: {
                    ...cookieTableStructure,
                    body: [
                        {
                            name: 'im_youtube',
                            description: 'Speichert die Erlaubnis für eingebettete YouTube-Videos',
                            duration: '365 Tage',
                        },
                        {
                            name: 'im_vimeo',
                            description: 'Speichert die Erlaubnis für eingebettete Vimeo-Videos',
                            duration: '365 Tage',
                        },
                        {
                            name: '__cf_bm',
                            description:
                                'Wird verwendet, um reguläre Benutzer von Bots zu unterscheiden.',
                            duration: '30 Minuten',
                        },
                        {
                            name: 'AWSALB, AWSALBCORS',
                            description:
                                'Wird vom Amazon Application Load Balancer für Load Balancing verwendet.',
                            duration: '7 Tage',
                        },
                    ],
                },
            },
        ],
    },
};

export { de };
